import React, { useState, useEffect } from 'react';
import { useEvents } from './EventsContext';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading';
import { Helmet } from 'react-helmet';

function AllEvents() {
  const { events } = useEvents();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (events.length > 0) {
      setIsLoading(false);
    }
  }, [events]);
  
  const handleNavigate = (event) => {
    navigate(`/tea-house/events/${event.id}`, { state: { event } });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <meta name="description" content="Explore our amazing events!" />
        <meta name="keywords" content="events, tea house, tea" />
      </Helmet>
      <ContainerEvents>
        {events?.map((event, index) => (
          <EventTile key={event.id} onClick={() => handleNavigate(event)} index={index}>
            <Helmet>
              <meta name="description" content={`${event?.description}`} />
              <meta name="keywords" content={`${event?.title}`} />
            </Helmet>
            <h2>{String(index + 1).padStart(2, '')}</h2>
            <h3>{event.title}</h3>
            <p>{event.description.slice(0, 100)}...</p>
            <button>Читать еще</button>
          </EventTile>
        ))}
      </ContainerEvents>
    </>
  );
}

const ContainerEvents = styled.ul`
  padding: 0;
  margin: auto;
  list-style: none;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 170px;
  @media(max-width: 768px){
    padding-top: 130px;
  }
`;

const EventTile = styled.li`
  display: inline-block;
  width: 20%;
  min-width: 200px;
  max-width: 230px;
  padding: 80px 20px 40px;
  position: relative;
  vertical-align: top;
  margin: 10px;
  font-family: 'Helvetica', sans-serif;
  min-height: 25vh;
  background:  #eae3d1;
  border: 1px solid #252727;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  h2 {
    font-size: 114px;
    margin: 0;
    position: absolute;
    opacity: 0.2;
    top: 50px;
    right: 10px;
    transition: all 0.3s ease-in-out;
  }

  h3 {
    font-size: 20px;
    color: #b7b7b7;
    margin-bottom: 5px;
  }

  p {
    font-size: 16px;
    line-height: 18px;
    color: #b7b7b7;
    margin-top: 5px;
  }

  button {
    background: black;
    border: 1px solid #b7b7b7;
    // background: ${({ index }) => generateGradient(index)};
    padding: 10px 20px;
    color: #b7b7b7;
    border-radius: 3px;
    position: relative;
    transition: all 0.3s ease-in-out;
    transform: translateY(-40px);
    opacity: 0;
    cursor: pointer;
    overflow: hidden;
    
  }


  button:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 120%;
    // background: white;
    top: 0;
    opacity: 0;
    left: -140px;
    border-radius: 0 20px 20px 0;
    z-index: -1;
    transition: all 0.3s ease-in-out;
  }

  &:hover button {
    transform: translateY(5px);
    opacity: 0.6;
  }

  button:hover {
    color: #262a2b;
    // background: #b7b7b7;
  }

  button:hover:before {
    left: 0;
    opacity: 0.6;
  }

  &:hover h2 {
    top: 0px;
    opacity: 0.6;
  }

  &:before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    background: ${({ index }) => generateGradient(index)};
    transform: skew(2deg, 2deg);
  }

  &:after {
    content: '';
    position: absolute;
    width: 40%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.02);
  }
`;

const generateGradient = (index) => {
  const gradients = [
    'linear-gradient(to right, #4A5A5B, #283E36)',  // Dark gray to dark green
    'linear-gradient(to right, #6D7577, #3B4C47)',  // Medium gray to muted green
    'linear-gradient(to right, #8C8F91, #2D3A33)',  // Light gray to dark forest green
    'linear-gradient(to right, #A3A6A8, #1F2A24)',  // Light gray to deep dark green
    'linear-gradient(to right, #5F6E6F, #374D42)',  // Steely gray to greenish gray
    'linear-gradient(to right, #7A7F82, #2F4035)',  // Neutral gray to green slate
  ];
  return gradients[index % gradients.length];
};


export default AllEvents;




