import React, { createContext, useContext, useState, useMemo } from 'react';
import baseUrl from '../../baseURL';
import axios from 'axios';
const CategoryContext = createContext();

export const CategoryProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  useMemo(() => {
    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${baseUrl}/tea-house/categories`);
            setCategories(response.data);
            
        } catch (error) {
            console.log(error.message);
        }
    };

    fetchCategories();
}, []);
  return (
    <CategoryContext.Provider value={{ categories }}>
      {children}
    </CategoryContext.Provider>
  );
};

export const useCategories = () => {
  return useContext(CategoryContext);
};