import React, { useEffect, useState } from 'react';
import { useCart } from '../Cart/CartContext';
import styled from 'styled-components';
import CustomerDetails from './CustomerDetails';
import { FaShoppingCart } from 'react-icons/fa'; // Importing shopping cart icon

function CartPopup({ close }) {
    const { cart, removeFromCart } = useCart();
    const [summary, setSummary] = useState(0);
    const [isSentToCust, setIsSentToCust] = useState(false);
    useEffect(() => {
        const total = cart.reduce((acc, product) => acc + parseFloat(product.price), 0);
        setSummary(total.toFixed(2));
    }, [cart]);

    return (
        <PopupContainer>
            <CloseButton onClick={close}>закрыть</CloseButton>
            {cart.length === 0 && !isSentToCust ? (
                <EmptyCartContainer>
                    <FaShoppingCartEmpty />
                    <EmptyCartText>Корзина пуста</EmptyCartText>
                </EmptyCartContainer>
            ) : (
                <CartItems>
                    {cart.map((product, index) => (
                        <CartItem key={index}>
                            <ProductImage src={product.image} alt="Product Image" />
                            <ProductDetails>
                                <ProductName>{product.title}</ProductName>
                                <ProductPrice>&#8362; {product.price}</ProductPrice>
                                <RemoveButton onClick={(e) => {
                                    e.stopPropagation(); 
                                    removeFromCart(index);
                                  }}>Удалить</RemoveButton>

                            </ProductDetails>
                        </CartItem>
                    ))}
                   {!isSentToCust && <SummaryContainer>
                        <TotalPrice>Итого: &#8362; {summary} <br></br>Товаров: {cart.length}</TotalPrice>
                    </SummaryContainer>}
                    <CustomerDetails summary={summary} cart={cart} close={close} isSentToCust={isSentToCust} setIsSentToCust={setIsSentToCust}/>
                </CartItems>
            )}
        </PopupContainer>
    );
}
const CartItem = styled.div`
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: space-between; 
  border-bottom: 1px solid #eee;
  padding: 10px 0;
`;

const ProductImage = styled.img`
  flex: 0 0 50px;
  height: 50px;
  margin-right: 10px;

  
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1; 
  margin-right: 10px;
`;

const ProductName = styled.h4`
  font-size: 16px;
  color: #2e4f24;
  margin: auto;
  margin-bottom: 5px;

  @media(max-width: 768px){
    font-size: 11px;
  }
`;

const ProductPrice = styled.h4`
  font-size: 15px;
  color: #2e4f24;
  margin: auto;
  margin-bottom: 5px;
  @media(max-width: 768px){
    font-size: 11px;
  }
`;

const RemoveButton = styled.button`
  flex: 0 0 auto; 
  background-color:#eae3d1;
  color: #2e4f24;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  margin: auto;
  width: 220px;
  text-align:center;
  @media(max-width: 768px){
    width: 125px;
  }
`;

const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 420px;
  max-height: 80vh;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 20px;
  z-index: 1000;

  @media(max-width: 768px){
    width: 220px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;

  @media(max-width: 768px){
    font-size: 12px;
  }

`;

const CartItems = styled.div`
  margin-top: 20px;
`;
const EmptyCartContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const FaShoppingCartEmpty = styled(FaShoppingCart)`
    font-size: 48px;
    color: beige;
`;

const EmptyCartText = styled.p`
    margin-top: 20px;
    font-size: 18px;
    color: rgba(0, 128, 0, 0.6);
    text-transform: uppercase;

`;

const SummaryContainer = styled.div`
    margin-top: 24px;
    text-transform: uppercase;
`;

const TotalPrice = styled.h3`
    text-align: center;
    color: #2e4f24;
    padding: 10px;
`;




export default CartPopup;
